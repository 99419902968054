@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../assets/fonts/noto-sans-georgian/regular.css";
@import "../assets/fonts/noto-sans-georgian/bold.css";
@import "../assets/fonts/poppins/poppins.css";

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: theme("colors.primary.200");
  border-radius: 3.5px;
}

@layer utilities {
  .row {
    /* --tw-gutter-x: 1.5rem;
        --tw-gutter-y: 0;
        margin-top: calc(-1 * var(--tw-gutter-y));
        margin-right: calc(-0.5 * var(--tw-gutter-x));
        margin-left: calc(-0.5 * var(--tw-gutter-x)); */
    @apply flex flex-wrap;
  }

  .g-y-6 {
    --tw-gutter-y: 1.5rem;
  }

  .g-x-6 {
    --tw-gutter-x: 3rem;
  }

  .g-6 {
    --tw-gutter-y: 3rem;
    --tw-gutter-x: 3rem;
  }

  .col {
    /* padding-right: calc(var(--tw-gutter-x) * 0.5);
        padding-left: calc(var(--tw-gutter-x) * 0.5);
        margin-top: var(--tw-gutter-y); */
    @apply max-w-full;
  }
}
